// bootstrap.js

"use strict";

import angular from "angular";
import { EMPLEADOS_LOGIN_SWENO_ENERGIA } from "../app";
// Importar scripts externos (antes o después del bootstrap, según necesidad)
import "../assets/js/gtm.js";
import "../assets/js/analytics.js";

angular.bootstrap(document, [EMPLEADOS_LOGIN_SWENO_ENERGIA], {
  strictDi: true
});
