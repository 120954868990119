// cookies.module.js
/* eslint-disable-next-line no-unused-vars */
/* global gtag */

"use strict";

import angular from "angular";
import "angular-cookies";
import { COOKIES_COMPONENT } from "./cookies.component";
import { COOKIES_SERVICE } from "./cookies.service";
import { COOKIES_RUN } from "./cookies.run";
import { COOKIES_DIALOG_MODULE } from "./cookies-dialog/cookies-dialog.module";
import "ng-dialog";
import {gtag} from "../../assets/js/analytics";

const COOKIES_MODULE = angular
    .module("cookies", ["ngCookies", "ngDialog", COOKIES_DIALOG_MODULE])
    .run(COOKIES_RUN)
    .service("cookiesService", COOKIES_SERVICE)
    .component("cookies", COOKIES_COMPONENT)
    .constant("gtag", gtag)
    .name
;

export { COOKIES_MODULE };
